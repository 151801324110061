import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/_layout";
import MarkdownContent from "@components/markdown/markdown-content";
import "./404.scss";

export default function NotFoundLayout({
  data: { mdx: { frontmatter: { title }, body: content } },
  pageContext
}) {
  return (
    <Layout title={title} {...pageContext}>
      <main id="main" className="NotFoundPage">
        <div className="NotFoundPage__text">
          <MarkdownContent>
            {content}
          </MarkdownContent>
        </div>
      </main>
    </Layout>
  )
};

export const pageQuery = graphql`
query NotFound($id: String!) {
  mdx(id: { eq: $id }) {
      frontmatter {
        title,
      }
      body
    },
  }
`;